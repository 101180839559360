import React, { createContext } from 'react';
export const ContainerContext = createContext()

const ContainerContextProvider = ({children}) => {
    return ( 
        <ContainerContext.Provider >
            { children }
        </ContainerContext.Provider>
     );
}
 
export default ContainerContextProvider;