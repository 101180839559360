import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";

import Main from '../pages/Main';
import NotFound from '../pages/NotFound';

const Urls = () => {
  return (
    <Router>
        <Routes>
          <Route exact path="/" element={<Main />}/>
          <Route path="*" element={<NotFound />} />
        </Routes>
    </Router>
  );
}

export default Urls;