import React from "react";
import { createRoot } from "react-dom/client";
import Urls from "./routes";
import * as serviceWorker from "./serviceWorker";

import { HelmetProvider } from "react-helmet-async";
import ContainerContextProvider from "./contexts/ContainerContext";

import "./theme.css";
import 'animate.css';

const root = createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <HelmetProvider>
      <ContainerContextProvider>
          <Urls />
      </ContainerContextProvider>
    </HelmetProvider>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
