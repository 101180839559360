import React from "react";
import Layout from "../components/Layout";

import NapoleonBonaparte from "./images/napoleon-bonaparte.jpg";
import Image1595 from "./images/1595.png";
import Image1880 from "./images/1880.png";
import Image1987 from "./images/1987.png";
import Image2024 from "./images/2024.png";

const Main = () => {
  return (
    <Layout title="Cossacks NFT" description="">
      <div className="container">
        <div
          id="about"
          className="row pt-5 justify-content-center align-items-center"
        >
          <div className="col-md-3 pb-2">
            <img src={NapoleonBonaparte} className="img-thumbnail" />
          </div>
          <div className="col-md-9">
            <figure>
              <blockquote className="blockquote">
                <p className="mb-0">
                  Cossacks are the best light troops among all that exist. If I
                  had them in my army, I would go through all the world with
                  them.
                </p>
              </blockquote>
              <figcaption className="blockquote-footer">
                Napoleon Bonaparte
              </figcaption>
            </figure>
          </div>
        </div>
        <div className="row pt-5">
          <div className="col-12">
            <p>
              Thanks to the great Napoleon Bonaparte for the free publicity!
              What he said is true! But how do we recognize real Cossacks in the
              digital world? What should be their distinguishing feature? First
              of all, freedom, and secondly, it is to look to the future with
              hope. When you buy and use a picture in your profiles, you will
              immediately feel part of a large and vital community. Let's take a
              look at how the Cossacks have evolved throughout history:
            </p>
          </div>
          <div className="col-md-3 pb-3">
            <div className="card h-100">
              <img src={Image1595} className="card-img-top" />
              <div className="card-body text-center">
                <span className="badge bg-primary">1595 year</span>
              </div>
            </div>
          </div>
          <div className="col-md-3 pb-3">
            <div className="card h-100">
              <img src={Image1880} className="card-img-top" />
              <div className="card-body text-center">
                <span className="badge bg-primary">1880 year</span>
              </div>
            </div>
          </div>
          <div className="col-md-3 pb-3">
            <div className="card h-100">
              <img src={Image1987} className="card-img-top" />
              <div className="card-body text-center">
                <span className="badge bg-primary">1987 year</span>
              </div>
            </div>
          </div>
          <div className="col-md-3 pb-3">
            <div className="card h-100 animate__animated animate__flash delay-5s">
              <img src={Image2024} className="card-img-top" />
              <div className="card-body text-center">
                <span className="badge bg-primary">2024 year</span>
              </div>
            </div>
          </div>
        </div>
        <div className="row pt-5" id="roadmap">
          <div className="col-12">
            <h1>Roadmap</h1>
          </div>
          <div className="col-12 pt-3 pb-3">
            <div class="progress">
              <div
                class="progress-bar"
                role="progressbar"
                style={{
                  width: "50%",
                }}
                aria-valuenow="50"
                aria-valuemin="0"
                aria-valuemax="100"
              >
                50%
              </div>
            </div>
          </div>
          <div className="col-6">
            <ul className="list-group">
              <li className="list-group-item d-flex justify-content-between align-items-center">
                <s>1. Create an NFT collection</s>
              </li>
              <li className="list-group-item d-flex justify-content-between align-items-center">
                <s>2. Create a website</s>
              </li>
              <li className="list-group-item d-flex justify-content-between align-items-center">
                <s>3. Send a request to getgems.io</s>
              </li>
              <li className="list-group-item d-flex justify-content-between align-items-center">
                <s>4. Publish the collection on getgems.io</s>
              </li>
            </ul>
          </div>
          <div className="col-6">
            <ul className="list-group">
              <li className="list-group-item d-flex justify-content-between align-items-center">
                5. Create a community "Crypto Cossacks"
              </li>
              <li className="list-group-item d-flex justify-content-between align-items-center">
                6. Create monetization tools for community
              </li>
              <li className="list-group-item d-flex justify-content-between align-items-center">
                7. Become the most expensive collection in the world!
              </li>
              <li className="list-group-item d-flex justify-content-between align-items-center">
                8. Live forever! (Sorry, we're perfectionists)
              </li>
            </ul>
          </div>
        </div>
        <div
          className="row align-items-center justify-content-center pt-5"
          id="collection"
        >
          <div className="col-12">
            <h1>
              <s>Collection</s> Our Army
            </h1>
          </div>
          {Array.from(Array(18)).map((s, i) => (
            <div className="col-md-2 pb-2">
              <img src={`/cossacks/${i}.png`} className="img-thumbnail" />
            </div>
          ))}
          <div className="col-12 text-center pt-2">
            <h4 className="py-5 animate__animated animate__pulse animate__infinite infinite">
            <a href="https://getgems.io/cossacks">GETGETMS</a> / <a href="https://opensea.io/collection/cossacks-nft">Open Sea</a> /  <a href="https://raritysniper.com/nft-drops-calendar">NFT Drops</a> 
            </h4>
          </div>
        </div>
        <div className="row pt-5 pb-5" id="contact">
          <div className="col-md-3"></div>
          <div className="col-md-6">
            <h1>Contact</h1>
            <div className="mb-3">
              <label for="emailFormControlInput" className="form-label">
                Email address
              </label>
              <input
                type="email"
                className="form-control"
                id="emailFormControlInput"
                placeholder="name@example.com"
              />
            </div>
            <div className="mb-3">
              <label for="messageFormControlTextarea" className="form-label">
                Message
              </label>
              <textarea
                className="form-control"
                id="messageFormControlTextarea"
                rows="3"
              ></textarea>
            </div>

            <button type="button" className="btn btn-primary btn-lg">
              Send
            </button>
          </div>
          <div className="col-md-3"></div>
        </div>
        <div
          className="row align-items-center justify-content-center pt-5"
        >
          <div className="col-12 text-center pt-2">
            <b className="py-5">
              <a href="https://x.com/CossacksNFT_">Twitter</a> / <a href="t.me/cossacksnft">Telegram</a> 
            </b>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Main;
